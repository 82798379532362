import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('exchange', ['exchangeDigitalGift']),

    async handleExchangePoint () {
      this.onExchangePoint(this.exchangeDigitalGift)
    }
  }
}
