<template>
  <main :class="{ 'padding-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
    <div class="exchange-service-common">
      <!-- TITLE SERVICE -->
      <section class="intro-service container">
        <!-- INFO SERVICE -->
        <h3 class="sp title-name">映画GIFT</h3>
        <div class="title-left">
          <img src="../../../assets/images/movie-walker.png" alt="logo">
          <div class="info">
            <h3 class="pc title-name">映画GIFT</h3>
            <p>全国の映画館で、どんな作品でも観られるデジタルギフト「映画GIFT」<br>
            心に残る映画を贈ろう！<br><br>
            本商品は ムビチケのウェブサイトおよびMOVIE WALKERアプリで販売しているデジタル映画鑑賞券「ムビチケ」をご購入の際にご利用いただけるプリペイドコードです。<br><br>
            &nbsp;「ムビチケ」とは<br>
             全国の映画館で利用できる、ネットで座席指定可能なデジタル映画鑑賞券です。<br>
             ムビチケには料金がオトクな「ムビチケ前売券（オンライン）」と、上映中の映画を購入できる「ムビチケ鑑賞券」があります。<br>
            </p>
          </div>
        </div>

        <!-- RULES SERVICE -->
        <ul class="rules-service">
          <li>
            <span>交換日数</span>
            <p><b>{{ pointExchangeService.exchange_date }}</b></p>
          </li>
          <li>
            <span>交換手数料</span>
            <p><b>無料</b></p>
          </li>
          <li>
            <span>交換レート</span>
            <strong class="unit-gpoint"><div>1pt →</div><span>1円相当</span></strong>
          </li>
          <li>
            <span>最低交換ポイント</span>
            <p class="point">500pt</p>
          </li>
        </ul>
      </section>

      <!-- NOTE SERVICE -->
      <section class="note-service d-flex">
        <div class="container d-inline-block">
          <p>※本商品はデジタル映画鑑賞券「ムビチケ」ご購入の際にご利用いただけます。本商品を直接映画館にお持ちいただいてもご利用いただけません。<br>
          ※デジタル映画鑑賞券「ムビチケ」の販売作品はムビチケのウェブサイト（https://mvtk.jp）よりご確認ください。<br>
          ※ムビチケのウェブサイトで取り扱っているデジタル映画鑑賞券「ムビチケ」のご購入にご利用いただけます。未使用分は、次回ムビチケ購入時にご利用いただけます。<br>
          ※本商品の有効期間は発効日から最長6ヶ月です。期間をお確かめのうえ、有効期間内にご利用ください。<br>
          ※有効期間中か否かに関わらず、本商品の再発行・補償・キャンセル・変更・返金・払戻しなどはできません。また本商品を利用してご購入いただいたデジタル映画鑑賞券「ムビチケ」のキャンセル・変更・返金・払戻しはできません。<br>
          ※利用約款（https://mvtk.jp/MvtkGiftPolicy）、未使用残高、有効期間、その他詳細はムビチケのウェブサイトよりご確認ください。<br>
          ※ご利用には、インターネットに接続してムビチケのウェブサイトにアクセスできる環境が必要になります。<br>
          ※一部ブラウザには対応していない場合がございます。<br>
          ※低速なWi-Fi通信環境下においては、正常に動作しない場合がございます。<br>
          ※動作環境は変更になる場合がございます。予めご了承ください。<br><br>
          ▽映画GIFTの使い方<br>
          https://mvtk.jp/gift/eigagift_howtouse<br><br>
          ▽映画GIFT情報の確認(有効期間・残高など)<br>
          https://www.giftcard.ne.jp/gift/carduser/CardUserLoginPage/open.do?key=5312af5b831076f7<br><br>
          ▽販売中の「ムビチケ」<br>
          https://mvtk.jp
          </p>
        </div>
      </section>

      <!-- EXCHANGE POINT -->
      <div class="control-exchange">
        <!-- BTN EXCHANGE POINT -->
        <button @click="handleBeforeExchangePoint"
                v-if="profile && token"
                class="btn-exchange-point">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">ポイントを交換する
        </button>

        <!-- BTN redirect to login -->
        <button v-else @click="openPageNative('Login')"
                class="btn-exchange-point btn-redirect">
          すでに会員の方はこちらからログイン
        </button>

        <!-- BTN BACK -->
        <div class="link-back">
          <router-link class="btn-back"
                       to="/exchange-point">
            <span>
               <ChevronLeftIcon size="20" />ポイント交換先一覧へ戻る
            </span>
          </router-link>
        </div>
      </div>
    </div>

    <!-- MODAL INPUT EXCHANGE POINT -->
    <Modal @close-modal="flagModalInput = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalInput">
      <ValidationObserver
        tag="form"
        ref="observer"
        @submit.prevent="validateBeforeSubmit()"
        slot="body"
        class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3>ポイント交換先<br class="sp">&#12288;映画GIFT</h3>
        <p class="sub-title">映画GIFTへの交換となります。</p>

        <!-- CONTENT -->
        <div class="point-exchange">
          <span>交換</span>
          <InputField v-model="pointExchange"
                      field="交換ポイント"
                      type="text"
                      class="point-type"
                      :max-length="15"
                      @input="handleNumber($event)"
                      @focusout="handleNumberFocusOut()"
                      @focusin="handleNumberFocusIn($event)"
                      :rules="{
                        required: true,
                        number_exchange: true,
                        compare_point: {
                          first: pointExchange && parseInt(pointExchange.replace(/,/g, '')),
                          second: user_point.total_point_amount
                        },
                        ten_thousand_point: true
                      }"
                      vid="point"/>
          <span class="pc">pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange point-line">
          <span>付与</span>
          <InputField v-model="pointExchange"
                      class="point-type"
                      :disabled="true"/>
          <span class="pc">円相当</span>
        </div>

        <!-- NOTE -->
        <p class="note">交換するポイントを入力して交換ポイントを確定するボタンをクリックしてください。</p>
      </ValidationObserver>

      <div class="btn-footer"
           slot="footer">
        <button @click.prevent="validateBeforeSubmit">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換ポイントを確定する
        </button>
      </div>
    </Modal>

    <!-- MODAL CONFIRM EXCHANGE POINT -->
    <Modal @close-modal="flagModalConfirm = false"
           class="modal-center modal-exchange-point"
           v-if="flagModalConfirm">
      <div slot="body"
           class="content-body dot-money">
        <!-- TITLE MODAL -->
        <h3>ポイント交換先<br class="sp">&#12288;映画GIFT</h3>
        <p class="sub-title">映画GIFTの交換となります。</p>

        <!-- CONTENT -->
        <div class="point-exchange confirm"
             :class="{ 'point-input' : !flagModalConfirm }">
          <span>交換</span>
          <strong class="point">{{ pointExchange }}</strong>
          <span>pt</span>
        </div>

        <!-- IMG ICON ARROW DOWN -->
        <img class="pc" src="../../../assets/images/icon/arrow-down.svg" alt="icon">
        <img v-if="flagModalConfirm" class="sp" src="../../../assets/images/icon/arrow-down-sp.svg" alt="icon">

        <div class="point-exchange confirm">
          <span :class="{ 'orange': !flagModalConfirm }">付与</span>
          <strong>{{ pointExchange }}</strong>
          <span :class="{ 'orange': !flagModalConfirm }">円相当</span>
        </div>

        <!-- NOTE MODAL CONFIRM -->
        <p class="note text-center">交換申請するボタンをクリックするとポイント交換が完了となります。</p>
      </div>

      <div class="btn-footer"
           slot="footer">
        <button @click="handleExchangePoint">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換申請する
        </button>
      </div>
    </Modal>

    <!-- MODAL COMPLETE EXCHANGE POINT -->
    <Modal @close-modal="handleCloseModal"
           class="modal-exchange-point modal-center"
           v-if="flagModalComplete">
      <div class="content-body"
           slot="body">
        <h3>ポイント交換先<br class="sp">&#12288;映画GIFT</h3>
        <div class="point-exchange mb-2 has-point">
          <span class="color-orange">付与ポイント</span>
          <div>
            <strong>{{ pointExchange }}<span class="color-orange sp" v-html="'円相当'"></span></strong>
            <time
              class="time-request"
              v-if="isExchangeAutoSuccess"
            >
              交換承認日：
              <span>
                {{ new Date() | formatDate('YYYY年MM月DD日') }}
              </span>
            </time>
          </div>
          <span class="pc color-orange"
                v-html="'円相当'"></span>
        </div>

        <p
          class="text-instruct"
          v-if="isExchangeAutoSuccess"
        >
          「映画GIFTを表示」ボタンからギフトをお受け取りください
        </p>

        <p
          class="text-instruct manual"
          v-if="!isExchangeAutoSuccess"
        >
          ポイント交換の申請を受け付けました。<br>承認されましたら、マイページのポイント交換履歴から、ギフトの受け取りができるようになります。
        </p>
      </div>

      <div class="btn-footer"
           slot="footer">
        <button
          class="btn-common"
          @click.prevent="openUrl(dataComplete.url)"
          v-if="isExchangeAutoSuccess"
        >
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">映画GIFTを表示
        </button>
      </div>
    </Modal>

    <!-- MODAL NOTIFY -->
    <Modal @close-modal="turnOffNoti"
           class="modal-center modal-exchange-error"
           v-if="flagModalNoti">
      <div slot="body">
        <p v-html="messageNotification"/>

        <!-- button in modal ostiaries_error -->
        <button v-if="this.$route.meta.ostiaries_error"
                @click.prevent="handleReturn"
                v-html="(this.$route.meta.ostiaries_error === 'true') ? '番号入力画面に戻る' : '完了'"
                class="btn-into-modal"/>
      </div>
    </Modal>

    <!-- MODAL CAUTION INFO -->
      <Modal @close-modal="flagModalRedirect = false"
           class="modal-center modal-notify"
           v-if="flagModalRedirect">
          <div slot="body">
            <p>ポイント交換前に必要事項をご入力ください。</p>

            <button
              @click.prevent="handelRedirectUpdateInfo"
              v-html="'会員情報入力'"
              class="btn-into-modal"
            />
          </div>
    </Modal>

    <!-- MODAL VERIFY AUTHEN MOBILE -->
    <ModalVerifyAuthenMobile @close-modal="flagModalVerifyAuth = false"
                              v-if="flagModalVerifyAuth"/>

    <!-- MODAL NOTIFY LINE_PAY AUTHENTICATION-->
    <!-- <ModalLinePayNotification
      :turnOffLinePayNotification="turnOffLinePayNotification"
      :isLinePayValidationError="isLinePayValidationError"
      :linePayValidationErrorMessage="linePayValidationErrorMessage"/> -->
  </main>
</template>

<script>
import { ChevronLeftIcon } from 'vue-feather-icons'
import Common from '@/mixins/common.mixin'
import SNS from '@/mixins/sns.mixin'
import DigitalGift from '@/mixins/digitalGift.mixin'
import store from '@/store'
import Modal from '@/components/advertising-detail/Modal'
import ModalVerifyAuthenMobile from '@/components/ModalVerifyAuthenMobile'
import InputField from '@/components/form/InputField'
import { EXCHANGE_POINT_SERVICE } from '@/enum/exchange-service'

export default {
  name: 'Index',

  mixins: [Common, SNS, DigitalGift],

  components: {
    ModalVerifyAuthenMobile,
    InputField,
    Modal,
    ChevronLeftIcon
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})

      if (this.token) {
        store.dispatch('auth/userPoint')
      }
    }
  },

  computed: {
    pointExchangePayload () {
      return {
        service_id: EXCHANGE_POINT_SERVICE.movieWalker.id,
        point: this.pointExchangeMapping()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/exchange-service.scss';
</style>
